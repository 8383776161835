import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { IWorkflowsTableContent } from "../../@types/Worflows";
import { useWorkflows } from "../../hooks/queries/workflows";
import { PageLayout } from "../../layout/components/PageLayout";
import { PageTitle } from "../../layout/components/PageTitle";
import { getLongFormattedDate } from "../../utils/dateFormatter";
import { WorflowListTable } from "./components/WorflowListTable";
import {
  AddWorkflowButton,
  PageTitleContainer,
  WorflowsTableContainer,
  WorkflowListContainer,
} from "./PatientWorkflows.styles";

export function WorkflowsList() {
  const navigate = useNavigate();

  const { workflows, isFetching, isRefetching } = useWorkflows();

  const parsedArticles = useMemo(() => {
    if (!workflows || workflows.length === 0) return [];

    return workflows?.map((workflow) => {
      return {
        id: workflow.id,
        title: workflow.title,
        description: workflow.description,
        updated:
          workflow.updated !== undefined
            ? getLongFormattedDate(workflow.updated)
            : getLongFormattedDate(workflow.created),
      } as IWorkflowsTableContent;
    });
  }, [workflows]);

  const handleOnRowClick = (rowContent: IWorkflowsTableContent) => {
    navigate(`/workflows/builder/${rowContent.id}`);
  };

  const handleAddWorkflowClick = () => {
    navigate("/workflows/builder");
  };

  return (
    <PageLayout>
      <WorkflowListContainer>
        <PageTitleContainer>
          <PageTitle>Workflows</PageTitle>
        </PageTitleContainer>

        <WorflowsTableContainer>
          <AddWorkflowButton onClick={handleAddWorkflowClick}>
            Add workflow
          </AddWorkflowButton>
          <WorflowListTable
            content={parsedArticles}
            isLoading={isFetching || isRefetching}
            onRowClick={handleOnRowClick}
          />
        </WorflowsTableContainer>
      </WorkflowListContainer>
    </PageLayout>
  );
}

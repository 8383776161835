import { useDrag } from "react-dnd";

import { NodeLibraryContainer } from "./NodesLibrary.styles";

function DraggableNode({ type, label }: any) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "NODE",
    item: { type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        padding: 10,
        border: "1px solid black",
        borderRadius: 5,
        background: "#f0f0f0",
        opacity: isDragging ? 0.5 : 1,
        cursor: "grab",
      }}
    >
      {label}
    </div>
  );
}

export function NodeLibrary() {
  return (
    <NodeLibraryContainer>
      <DraggableNode type="ruleNode" label="Rule" />
      <DraggableNode type="responseNode" label="Response" />
    </NodeLibraryContainer>
  );
}

import { css, styled } from "@patientmpower/spiro";

// Components

export const WorkflowsBuilderContainer = styled("div", {
  width: "100vw",
  height: "100vh",
  position: "relative",
  marginTop: "-62px",
  zIndex: 2001,
  backgroundColor: "$gray-20",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Header = styled("div", {
  width: "100%",
  height: "62px",

  position: "absolute",

  backgroundColor: "$primary-80",
  padding: "0 40px 0 68px",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",

  display: "flex",
  alignItems: "center",

  p: {
    color: "$white",
    fontSize: "16px",
    fontWeight: "00",
    lineHeight: "22px",
  },
});

export const ButtonsContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "12px",

  position: "absolute",
  right: "40px",
});

export const CancelButton = styled("div", {
  width: "110px !important",
  height: "38px",

  padding: "0px 40px",
  borderRadius: "20px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "22px",
  color: "$white",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
  },

  "&:focus-visible": {
    outline: "2px solid white !important",
  },
});

export const SectionsHeader = styled("div", {
  width: "100%",
  height: "62px",
  marginTop: "62px",
  backgroundColor: "$gray-20",
  padding: "0 40px 0 68px",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",
  zIndex: 100,
});

export const SectionsContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "42px",
});

export const SectionButton = styled("div", {
  color: "$gray-90",
  fontFamily: "$openSans",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "22px",
  marginTop: "20px",

  cursor: "pointer",

  "&:hover": {
    div: {
      backgroundColor: "$primary-20",
    },
  },

  "&:focus": {
    outline: "none",

    div: {
      backgroundColor: "$primary-20",
    },
  },
});

export const Underline = styled("div", {
  width: "100%",
  height: "5px",

  borderRadius: "2px",
  marginTop: "15px",

  variants: {
    isActive: {
      true: {
        backgroundColor: "$primary-30 !important",
      },
    },
  },
});

export const ReactFlowContainer = styled("div", {
  width: "calc(100vw - 400px)",
  height: "calc(100vh - 124px)",

  display: "flex",

  backgroundColor: "$white",

  ".node-wrapper": {
    pointerEvents: "all !important",
  },

  ".node-wrapper .interactive-element": {
    pointerEvents: "all !important",
  },
});

// CSS

export const spinCss = css({
  backgroundColor: "white !important",

  ".ant-spin-dot": {
    ".ant-spin-dot-item": {
      backgroundColor: "#7562A8",
    },
  },
});

export const whiteButton = css({
  button: {
    backgroundColor: "$white !important",
    borderColor: "$white !important",

    p: {
      color: "$primary-70 !important",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },

    "&:hover": {
      backgroundColor: "$gray-40 !important",
    },

    "&:focus-visible": {
      outline: "2px solid $primary-30 !important",
    },
  },
});

export const purpleButton = css({
  button: {
    backgroundColor: "$primary-40 !important",
    borderColor: "$primary-40 !important",

    p: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },

    "&:hover": {
      backgroundColor: "$primary-30 !important",
    },

    "&:focus-visible": {
      outline: "2px solid white !important",
    },
  },
});

export const titleTextField = css({
  width: "160px",
  height: "40px",
  position: "absolute",
  left: "30px",
  top: "300px",
});

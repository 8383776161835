import { Button, TextField } from "@patientmpower/spiro";
import { Spin } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrop } from "react-dnd";
import { useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ConnectionLineType,
  Edge,
  Connection,
  reconnectEdge,
  useReactFlow,
  XYPosition,
  NodeChange,
} from "reactflow";
import "reactflow/dist/style.css";
import { v4 as uuidv4 } from "uuid";

import { IMeasurementTypeOptions } from "../../../../@types/Measurements";
import {
  IWorkflow,
  IWorkflowResponse,
  IWorkflowRule,
  IWorkflowsSurveyOption,
  IWorkflowStep,
  IWorkflowVersion,
} from "../../../../@types/Worflows";
import { useWorkflowsSelectOptions } from "../../../../hooks/queries/workflows";
import { measurementsService } from "../../../../services/measurementsService";
import { workflowsService } from "../../../../services/worflowsService";
import { getLongFormattedDate } from "../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { NodeLibrary } from "./components/NodesLibrary";
import { ResponseNode } from "./components/ResponseComponent";
import { RuleNode } from "./components/RuleComponent";
import {
  ButtonsContainer,
  CancelButton,
  Header,
  purpleButton,
  ReactFlowContainer,
  SectionButton,
  SectionsContainer,
  SectionsHeader,
  spinCss,
  titleTextField,
  Underline,
  whiteButton,
  WorkflowsBuilderContainer,
} from "./WorkflowsBuilder.styles";

export function WorkflowsBuilder() {
  const navigate = useNavigate();
  const { screenToFlowPosition } = useReactFlow();
  const { workflowId } = useParams();
  const {
    ruleTypes,
    ruleConditionComparators,
    ruleConditionTriggers,
    responseTypes,
    responseTimes,
    actionTypes,
    isFetching,
    isRefetching,
  } = useWorkflowsSelectOptions();

  const [selectedSection, setSelectedSection] = useState<"flow" | "settings">(
    "flow"
  );

  const [firstStepId] = useState(uuidv4());
  const [nodes, setNodesState, onNodesChange] = useNodesState([]);
  const [edges, setEdgesState, onEdgesChange] = useEdgesState([]);

  const [workflowName, setWorkflowName] = useState<string>();
  const [wardIds] = useState([3, 10]);

  const [workflow, setWorkflow] = useState<IWorkflow>();
  const [measurementTypeOptions, setMeasurementTypeOptions] = useState<
    IMeasurementTypeOptions[]
  >([]);
  const [surveyOptions, setSurveyOptions] = useState<IWorkflowsSurveyOption[]>(
    []
  );

  const [whiteButtonIsLoading, setWhiteButtonIsLoading] = useState(false);
  const [purpleButtonIsLoading, setPurpleButtonIsLoading] = useState(false);
  const [areOptionsLoading, setAreOptionsLoading] = useState(false);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const edgeReconnectSuccessful = useRef(true);

  const handleCancel = () => {
    mixpanelActions.track("User Action: Cancel workflow");
    navigate("/workflows");
  };

  const handleOnSaveWorkflow = async (isPublished: boolean) => {
    setWhiteButtonIsLoading(true);
    setPurpleButtonIsLoading(true);

    const validations = await Promise.all(
      nodes.map(async (node: any) => {
        const formikRef = node.data.ref?.current;

        if (formikRef) {
          formikRef.submitForm();

          const errors = await formikRef.validateForm();

          return Object.keys(errors).length === 0;
        }

        return false;
      })
    );

    const areNodesValid = validations.every(Boolean);

    if (!areNodesValid) {
      setWhiteButtonIsLoading(false);
      setPurpleButtonIsLoading(false);

      return;
    }

    const workflowId = uuidv4();

    const steps = nodes.reduce((acc, node) => {
      const formikValues = node.data.ref?.current.values;

      const step: IWorkflowStep = {
        id: node.id,
        positionX: node.position.x,
        positionY: node.position.y,
        stepType: formikValues.ruleType ? "Rule" : "Response",
        ruleType: formikValues.ruleType,
        conditions: formikValues.conditions,
        responseType: formikValues.responseType,
        timeType: formikValues.responseTime,
        time: (formikValues.timeUtc as Dayjs)?.format("HH:mm"),
        messageText: formikValues.messageText,
        questionText: formikValues.questionText,
        option1Text: formikValues.option1Text,
        option2Text: formikValues.option2Text,
        actionType: formikValues.actionType,
        surveyId: formikValues.surveyId,
        pushNotificationMessage: formikValues.pushNotificationMessage,
        measurementType: formikValues.ruleType
          ? formikValues.conditions[0].measurementType
          : formikValues.measurementType,
      };

      acc[node.id] = step;
      return acc;
    }, {} as { [id: string]: IWorkflowStep });

    edges.forEach((edge) => {
      const { source, target, sourceHandle } = edge;

      if (sourceHandle === "true") {
        steps[source].nextStepOneId = target;
        steps[source].nextStepOne = steps[target];
      } else if (sourceHandle === "false") {
        steps[source].nextStepTwoId = target;
        steps[source].nextStepTwo = steps[target];
      }
    });

    const workflowVersion: IWorkflowVersion = {
      id: uuidv4(),
      firstStepId,
      firstStep: steps[firstStepId],
      workflowId,
    };

    const workflowToAdd: IWorkflow = {
      id: workflowId,
      title: workflowName ?? `Sample Workflow ${dayjs().toISOString}`,
      description: "This is a sample workflow",
      versions: [workflowVersion],
      wards: [3, 10],
      published: isPublished,
    };

    await workflowsService.addWorkflow(workflowToAdd);

    setWhiteButtonIsLoading(false);
    setPurpleButtonIsLoading(false);

    navigate("/workflows");
  };

  const onConnect = (conn: Connection) => {
    setEdgesState((eds) => addEdge({ ...conn, type: "step" }, eds));
  };

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeReconnectSuccessful.current = true;
      setEdgesState((els) => reconnectEdge(oldEdge, newConnection, els));
    },
    []
  );

  const onReconnectEnd = useCallback(
    (_: MouseEvent | TouchEvent, edge: Edge) => {
      if (!edgeReconnectSuccessful.current) {
        setEdgesState((eds) => eds.filter((e) => e.id !== edge.id));
      }

      edgeReconnectSuccessful.current = true;
    },
    []
  );

  const handleNodesChanges = (changes: NodeChange[]) => {
    const filteredChanges = changes.filter(
      (node) => !(node.type === "remove" && node.id === firstStepId)
    );

    onNodesChange(filteredChanges);
  };

  const [, drop] = useDrop({
    accept: "NODE",
    drop: (item: any, monitor) => {
      const offset = monitor.getClientOffset();
      const { x, y } = offset ?? { x: 0, y: 0 };

      const position = screenToFlowPosition({
        x,
        y,
      });

      const formikRef = React.createRef<FormikProps<any>>();

      const newNode = {
        id: uuidv4(),
        type: item.type,
        position,
        data: {
          ruleTypes,
          ruleConditionComparators,
          ruleConditionTriggers,
          responseTypes,
          responseTimes,
          actionTypes,
          surveyOptions,
          measurementTypeOptions,
          ref: formikRef,
        },
      };

      setNodesState((prevNodes: any) => [...prevNodes, newNode]);
    },
  });

  const nodeTypes = useMemo(
    () => ({ ruleNode: RuleNode, responseNode: ResponseNode }),
    []
  );

  useEffect(() => {
    if (!workflowId && !isFetching && !isRefetching && !areOptionsLoading) {
      const formikRef = React.createRef<FormikProps<any>>();

      const newNode = {
        id: firstStepId,
        type: "ruleNode",
        position: { x: 0, y: 0 } as XYPosition,
        data: {
          ruleTypes,
          ruleConditionComparators,
          ruleConditionTriggers,
          responseTypes,
          responseTimes,
          actionTypes,
          surveyOptions,
          measurementTypeOptions,
          ref: formikRef,
          isFirstStep: true,
        },
      };

      setNodesState((prevNodes: any) => [...prevNodes, newNode]);
    }
  }, [workflowId, isFetching, isRefetching, areOptionsLoading]);

  useEffect(() => {
    if (wardIds === undefined) return;

    setAreOptionsLoading(true);

    workflowsService.getWorflowsSurveyOptions(wardIds).then((surveys) => {
      setSurveyOptions(surveys.data);

      measurementsService
        .getMeasurementTypesByWards(wardIds, true)
        .then((measurementTypes) => {
          setMeasurementTypeOptions(measurementTypes.data);

          const updatedNodes = nodes.map((node) => {
            if (node.type === "responseNode") {
              const surveyIndex = surveys?.data?.findIndex(
                (survey) =>
                  survey.surveyId === node.data.ref?.current?.values.surveyId
              );

              if (surveyIndex === -1) {
                node.data.ref?.current?.setFieldValue("surveyId", undefined);
              }
            }

            const measurementTypeIndex = measurementTypes?.data?.findIndex(
              (measurementType) =>
                measurementType.measurementType ===
                node.data.ref?.current?.values.measurementType
            );

            if (measurementTypeIndex === -1) {
              node.data.ref?.current?.setFieldValue(
                "measurementType",
                undefined
              );
            }

            return {
              ...node,
              data: {
                ...node.data,
                measurementTypeOptions: measurementTypes.data,
                surveyOptions: surveys.data,
              },
            };
          });

          setNodesState(updatedNodes);

          setAreOptionsLoading(false);
        });
    });
  }, [wardIds]);

  const addStepNodeAndEdge = (step: IWorkflowStep) => {
    const formikRef =
      React.createRef<FormikProps<IWorkflowRule | IWorkflowResponse>>();

    const nodeContent: IWorkflowRule | IWorkflowResponse = {
      id: step.id,
      ruleType: step.ruleType,
      measurementType: step.measurementType,
      conditions: step.conditions,
      responseType: step.responseType,
      actionType: step.actionType,
      surveyId: step.surveyId,
      responseTime: step.timeType,
      timeUtc: step.time !== undefined ? dayjs(step.time) : undefined,
      messageText: step.messageText,
      questionText: step.questionText,
      option1Text: step.option1Text,
      option2Text: step.option2Text,
    };

    const newNode = {
      id: step.id,
      type: step.stepType === "Rule" ? "ruleNode" : "responseNode",
      position: { x: step.positionX || 0, y: step.positionY || 0 },
      data: {
        ruleTypes,
        ruleConditionComparators,
        ruleConditionTriggers,
        responseTypes,
        responseTimes,
        actionTypes,
        surveyOptions,
        measurementTypeOptions,
        ref: formikRef,
        isFirstStep: true,
        content: nodeContent,
      },
    };

    setNodesState((prevNodes: any) => [...prevNodes, newNode]);

    if (step.nextStepOne && step.nextStepOneId && step.id) {
      const edge: Edge = {
        id: `edge-${step.id}-${step.nextStepOne.id}`,
        source: step.id,
        target: step.nextStepOneId,
        sourceHandle: "true",
      };

      setEdgesState((eds) => addEdge({ ...edge, type: "step" }, eds));

      addStepNodeAndEdge(step.nextStepOne);
    }

    if (step.nextStepTwo && step.nextStepTwoId && step.id) {
      const edge: Edge = {
        id: `edge-${step.id}-${step.nextStepOneId}`,
        source: step.id,
        target: step.nextStepTwoId,
        sourceHandle: "false",
      };

      setEdgesState((eds) => addEdge({ ...edge, type: "step" }, eds));

      addStepNodeAndEdge(step.nextStepTwo);
    }
  };

  const handleWorkflowNodes = (workflow: IWorkflow) => {
    if (!workflow) return;

    const firstVersion = workflow.versions[0];
    const { firstStep } = firstVersion;

    if (firstStep) {
      addStepNodeAndEdge(firstStep);
    }
  };

  useEffect(() => {
    if (
      workflowId !== undefined &&
      !isFetching &&
      !isRefetching &&
      !areOptionsLoading
    ) {
      setIsWorkflowLoading(true);

      workflowsService.getWorkflowById(workflowId).then((workflow) => {
        setWorkflow(workflow.data);

        handleWorkflowNodes(workflow.data);

        setIsWorkflowLoading(false);
      });
    }
  }, [workflowId, isFetching, isRefetching, areOptionsLoading]);

  return (
    <WorkflowsBuilderContainer>
      {isFetching || isRefetching || areOptionsLoading || isWorkflowLoading ? (
        <Spin className={spinCss()} fullscreen size="large" />
      ) : (
        <>
          <Header>
            {workflow && (
              <p>
                * Last edited{" "}
                {getLongFormattedDate(
                  workflow.updated ? workflow.updated : workflow.created
                )}
              </p>
            )}
            <ButtonsContainer>
              <CancelButton onClick={handleCancel} tabIndex={0}>
                Cancel
              </CancelButton>
              <Button
                label={workflow?.published ? "Unpublish" : "Save"}
                type="submit"
                onClick={() => handleOnSaveWorkflow(false)}
                className={purpleButton()}
                isLoading={purpleButtonIsLoading}
              />
              <Button
                label={workflow?.published ? "Publish changes" : "Publish"}
                type="button"
                onClick={() => handleOnSaveWorkflow(true)}
                className={whiteButton()}
                isLoading={whiteButtonIsLoading}
              />
            </ButtonsContainer>
          </Header>

          <SectionsHeader>
            <SectionsContainer>
              <SectionButton
                onClick={() => {
                  mixpanelActions.track(
                    `UserAction: FlowTab${
                      workflow?.title ? `[${workflow.title}]` : ""
                    }`
                  );
                  setSelectedSection("flow");
                }}
                onKeyDown={() => {
                  mixpanelActions.track(
                    `UserAction: FlowTab${
                      workflow?.title ? `[${workflow.title}]` : ""
                    }`
                  );
                  setSelectedSection("flow");
                }}
                tabIndex={0}
              >
                <p style={{ width: 67, textAlign: "center" }}>Flow</p>
                <Underline isActive={selectedSection === "flow"} />
              </SectionButton>
              <SectionButton
                onClick={() => {
                  mixpanelActions.track("UserAction: SettingsTab");
                  setSelectedSection("settings");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    mixpanelActions.track("UserAction: SettingsTab");
                    setSelectedSection("settings");
                  }
                }}
                tabIndex={0}
              >
                <p>Settings</p>
                <Underline isActive={selectedSection === "settings"} />
              </SectionButton>
            </SectionsContainer>
          </SectionsHeader>
        </>
      )}

      <ReactFlowContainer>
        <NodeLibrary />

        <TextField
          label="Title"
          onChange={(event) => setWorkflowName(event.target.value)}
          className={titleTextField()}
          backgroudColor="white"
        />

        <div ref={drop} style={{ width: "100%", height: "100%" }}>
          <ReactFlow
            fitView
            ref={containerRef}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            maxZoom={1}
            minZoom={0.3}
            style={{ width: "100%", height: "100%" }}
            deleteKeyCode={["Delete", "Backspace"]}
            onNodesChange={handleNodesChanges}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onReconnect={onReconnect}
            onReconnectStart={onReconnectStart}
            onReconnectEnd={onReconnectEnd}
            connectionLineType={ConnectionLineType.SmoothStep}
          >
            <Background />
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowContainer>
    </WorkflowsBuilderContainer>
  );
}

import { styled } from "@patientmpower/spiro";

export const WorkflowListContainer = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",
});

export const PageTitleContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "12px",
});

export const WorflowsTableContainer = styled("div", {
  marginTop: "$4",

  height: "calc(100vh - 300px)",
  maxHeight: "800px",

  position: "relative",
});

export const AddWorkflowButton = styled("div", {
  width: "fit-content",
  height: "26px",
  padding: "5px 11px",

  borderRadius: "12px",
  backgroundColor: "$black",

  color: "$white",
  textAlign: "center",
  fontFamily: "$openSans",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",

  position: "absolute",
  right: "0",
  top: "-32px",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$charcoal",
  },
});

import axios, { AxiosError, AxiosResponse } from "axios";

import { IForgotPasswordFormValues } from "../@types/ForgotPassword";
import {
  IAuthAPIResponse,
  IConfirmLoginMfa,
  ILogin,
  ILoginResponse,
} from "../@types/Login";
import { ISetPasswordRequest, IPasswordPolicy } from "../@types/SetPassword";
import { api } from "./api";

export const authService = {
  async authenticate({ email, password }: ILogin): Promise<any> {
    const responseFromAPI = await api.post<IAuthAPIResponse>(
      "/Portal/Authentication/Login",
      {
        username: email,
        password,
      }
    );

    const { data } = responseFromAPI;

    return {
      token: data.token,
      preferences: data.preferences,
      portalPreferences: data.portalPreferences,
      mfaDetails: data.mfaDetails,
      intercomDetails: data.intercomDetails,
      userDetails: data.userDetails,
      hospitalConfiguration: data.hospitalConfiguration,
      errors: data.errors,
      staffPreferences: data.staffPreferences,
    } as ILoginResponse;
  },

  async confirmLoginMfa({
    email,
    code,
  }: IConfirmLoginMfa): Promise<AxiosResponse> {
    const url = "/Portal/Authentication/ConfirmLoginMfa";

    const serverResponse = await api
      .post(url, { email, code })
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async sendStaffForgotPasswordRequest({
    email,
  }: IForgotPasswordFormValues): Promise<void> {
    const url = "/Portal/Authentication/ForgotPassword";
    await api.post(url, { username: email });
  },

  async sendPatientForgotPasswordRequest({
    email,
  }: IForgotPasswordFormValues): Promise<void> {
    const url = "/Mobile/Authentication/ForgotPassword";
    await api.post(url, { username: email });
  },

  async setPassword({
    password,
    email,
    code,
  }: ISetPasswordRequest): Promise<AxiosResponse> {
    const url = "/Portal/Authentication/SetPassword";

    const serverResponse = await api
      .post(url, {
        username: email,
        newPassword: password,
        confirmationCode: code,
      })
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getPasswordPolicy(): Promise<IPasswordPolicy | undefined> {
    const url = "/Shared/Authentication/PasswordPolicy";

    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return undefined;
    }
  },

  async validateTokenCode(
    username: string,
    code: string
  ): Promise<AxiosResponse> {
    const url = `/Portal/Authentication/ValidateTokenCode`;
    const isValid = await api.post(url, {
      username,
      confirmationCode: code,
    });
    return isValid;
  },
};

import { useQuery } from "@tanstack/react-query";

import { articlesSerivce } from "../../services/articlesService";

export function useArticles(hospitalGroupId?: number) {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery(
    [`articles-list-${hospitalGroupId}`],
    async () => {
      const { data } = await articlesSerivce.gethospitalArticles(
        hospitalGroupId
      );

      return data;
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    articles: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function useHospitalArticles() {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery(
    [`hospital-articles-list`],
    async () => {
      const { data } = await articlesSerivce.getAllHospitalArticles();

      return data;
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    articles: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

import { styled } from "@patientmpower/spiro";

// Components

export const NodeLibraryContainer = styled("div", {
  width: 200,
  padding: 20,
  borderRight: "1px solid #ccc",
  height: "100%",
  marginLeft: "-200px",
});

import { createColumn } from "@patientmpower/spiro";

export const worflowsListColumns = [
  createColumn({
    keyName: "title",
    header: () => "Title",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "description",
    header: () => "Description",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "updated",
    header: () => "Last edited",
    cell: (info) => info.renderValue(),
  }),
];
